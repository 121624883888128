



































































































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  mixins: [],
  components: {},
  props: {},
  validations: {},
})

export default class SpinToWinOicialRules extends Vue {}
