



































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  mixins: [],
  components: {},
  props: {},
  validations: {},
})

export default class PrivacyPolicy extends Vue {
  openConsentModal() {
    this.$root.$emit('bv::show::modal', 'cookie-consent-modal');
  }
}
