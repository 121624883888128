






















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SiteLayout from '@/shared/components/site/SiteLayout.vue';
import TermsCondition from '@/shared/components/common/TermsCondition.vue';
import PrivacyPolicy from '@/shared/components/common/PrivacyPolicy.vue';
import CopyrightPolicy from '@/shared/components/common/CopyrightPolicy.vue';
import SpinToWinOfficalRules from '@/shared/components/common/SpinToWinOfficalRules.vue';

@Component({
  components: {
    SpinToWinOfficalRules,
    SiteLayout,
    TermsCondition,
    PrivacyPolicy,
    CopyrightPolicy,
  },
})
export default class Legal extends Vue {
  @Getter('layout/isMobile') isMobile: boolean;

  get activeTab() {
    return this.$route && this.$route.name ? this.$route.name : 'terms';
  }

  @Watch('activeTab')
  watchActiveTab() {
    window.scrollTo(0, 0);
  }
}
